import * as React from "react"
import "../styles/main.scss";
import { useState } from "react"
import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet";
import { Navigation, Content } from "../components";

interface Inputs {
    name: string;
    email: string;
    company: string;
    telephone: string;
    comments: string;
    source: string;
};

enum InputType {
    input,
    text
}
interface FIProps {
    id: string;
    label: string;
    register: any;//UseFormRegister<Inputs>;
    required?: boolean;
    inputType?: InputType;
    errors: any;
}

const Input = (props: FIProps) => {
    const properties = props.required ? { required: true } : {};

    switch (props.inputType) {
        case InputType.text:
            return <textarea {...props.register(props.id, properties)} type="text" name={props.id} id={`contact_${props.id}`} className="form-control" rows={3}></textarea>;
        case InputType.input:
        default:
            return <input {...props.register(props.id, properties)} type="text" name={props.id} id={`contact_${props.id}`} className="form-control" />;

    };
}

const FormInput = (props: FIProps) => {
    return <div className="row mb-2">
        <label htmlFor={`contact_${props.id}`} className="form-label col-12 col-md-4 py-md-2">
            {props.label}
            {props.required &&
                <span className="ms-2 text-info align-end">(Required)</span>
            }
        </label>
        <div className="col-12 col-md-8 px-md-0">
            <Input {...props} />
            {props.errors[props.id] && <div className="mt-2 text-danger font-bold">This field is required</div>}
        </div>
    </div>;
};

const Brochure = () => {
    const [pending, setPending] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [problem, setProblem] = useState<string>("");
    const { register, handleSubmit, formState: { errors } } = useForm<Inputs>();

    const onSubmit = (data: Inputs) => {
        setProblem("");
        setPending(true);

        try {
            fetch("https://api.viewpointbi.com/contact", {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                headers: {
                    "Content-Type": "application/json",
                    "Subscription-Key": "8b376fdb63994d408ba8247e59130975"
                },
                body: JSON.stringify(data)
            })
                .then((response) => {
                    if (response && response.ok) {
                        setPending(false);
                        setSubmitted(true);
                    } else {
                        setPending(false);
                        setProblem("Could not submit form.");
                    }
                })
                .catch(error => {
                    setPending(false);
                    setProblem(error);
                });
        } catch (error) {
            setPending(false);
            setProblem("Could not submit form.");
        }
    };

    return (
        <>
            <Helmet title="Qebal / Contact us" htmlAttributes={{ lang: 'en' }} />
            <Navigation />
            <Content hideContact>

                <div className="row">
                    <div className="col-12 col-md-8 px-2 px-md-0">
                        <h1>Download our brochure</h1>

                        <p className="my-4">
                            For a copy of our solution overview brochure, or to find out more about our platform,<br />
                            please provide your details below...
                        </p>
                    </div>
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <fieldset disabled={pending || submitted}>

                        <div className="row">
                            <div className="col-12 col-md-8 px-2 px-md-0 order-first">

                                <div className="shadow fs-6">
                                    <input {...register("source")} type="hidden" value="Qebal Brochure" name="source" id="contact_source" />

                                    {!submitted &&
                                        <div className="px-4 py-4 bg-white">
                                            <FormInput id="name" label="Your name" register={register} errors={errors} />
                                            <FormInput id="email" label="Email address" required register={register} errors={errors} />
                                            <FormInput id="company" label="Company name" register={register} errors={errors} />
                                            <FormInput id="telephone" label="Contact telephone number" inputType={InputType.input} register={register} errors={errors} />
                                            <FormInput id="comments" label="Any questions or comments" inputType={InputType.text} register={register} errors={errors} />
                                        </div>
                                    }
                                    <div className="px-4 py-3">
                                        {problem &&
                                            <div className="mb-2">
                                                Sorry, but we couldn&apos;t process your request.<br />
                                                Please try again, or contact us on <strong>+44(0)208&nbsp;1333&nbsp;555</strong> to talk to us.
                                            </div>
                                        }

                                        {!pending && !submitted &&
                                            <button type="submit" className="btn btn-primary">Next</button>
                                        }
                                        {pending &&
                                            <>One moment please...</>
                                        }

                                        {!pending && submitted &&
                                            <div className="my-4">
                                                <span className="fs-3"><strong>Thank you for requesting a brochure.</strong><br />We will be in touch soon.</span>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </fieldset>
                </form>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
            </Content>
        </>
    );
};

export default Brochure;
